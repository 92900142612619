import React from "react"

const BgGraphic = () => (
  <div className="text-white opacity-10">
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 1440 831" className="fill-current">
      <defs/>
      <path d="M715.243 867.993c-67.379-323.161-276.212-580.4-541.707-703.584-25.299-11.75-55.12-6.492-74.8747 13.203C2.54631 273.592-72.7978 392.027-120.235 524.456c-5.473 15.376-2.239 33.719 8.164 46.295C92.6965 814.972 403.306 946.863 720.612 890.914c237.282-41.84 435.238-181.221 565.158-372.776 44.39-65.692 80.57-136.47 108.06-212.692 5.69-15.414 2.41-33.975-8.2-46.514-89.87-108.218-201.37-194.834-324.15-251.31643-25.3-11.75077-55.08-6.27392-74.836 13.42103C849.81 157.07 755.158 340.379 721.699 543.296c-17.973 103.817-19.349 213.939-3.92 324.025"/>
    </svg>
  </div>
)

export default BgGraphic