import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Header from "../components/Header"
import SEO from "../components/seo";
import BgGraphic from "../components/bg-graphic"

import "../css/index.css"
const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          description
        }
      }
    }
  `)

  return (
    <>
    <SEO title="Frankie is coming soon..." description={data.site.siteMetadata.description} />
     <div className="min-h-screen bg-theme-blue flex items-center justify-center">
       <Header />
       <div className="absolute bottom-0 inset-x-0 overflow-hidden">
        <BgGraphic />
       </div>
     </div>
    </>
  )
}


export default IndexPage
