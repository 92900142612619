import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import lottie from 'lottie-web'

class Header extends React.Component {

  constructor(props) {
    super(props)
    this.container = React.createRef()
  }

  animation = () => {
    const data = {
      wrapper: this.container.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: '/logo-animation.json',
    }

    lottie.loadAnimation(data);
  }

  componentDidMount() {
    this.animation();
  }

  render() {
    return (
      <header className="text-center">
        <Link to="/">
          <div className="w-58 w-64 block h-16" ref={this.container}></div>
        </Link>
        <span className="inline-block mt-6 text-2xl text-white leading-none">Coming Soon&hellip;</span>
      </header>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
